import { loadForElements } from './helpers/module-loader';
import initSetCssProperty from './helpers/set-css-property';
import initShowMenu from './components/menu';
import initStickyHeader from './components/sticky-header';
import initShowSearchInput from './components/header-search';
import initCarousels from './components/carousels';
import cssHasPolyfill from './components/css-has-polyfill';

document.addEventListener('DOMContentLoaded', () => {
    initSetCssProperty();
    initShowMenu();
    initStickyHeader();
    initShowSearchInput();
    initCarousels();
    cssHasPolyfill();

    loadForElements(document.querySelectorAll('[data-mobile-menu-trigger]'), () => import('./components/mmenu'));
    loadForElements(document.querySelectorAll('a[data-lightbox]'), () => import('./helpers/lightbox'));
    loadForElements(document.querySelectorAll('.ce_accordion'), () => import('./helpers/accordion'));
    loadForElements(document.querySelectorAll('pre code'), () => import('./helpers/highlight'));
    loadForElements(document.querySelectorAll('[data-masonry]'), () => import('./components/masonry'));
    loadForElements(document.querySelectorAll('[data-member-catalog]'), () => import('./components/member-catalog'));
});
