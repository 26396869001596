export default function () {
    // Trigger menu
    const menuTrigger = document.querySelector('[data-menu-trigger]');

    menuTrigger.addEventListener('click', () => {
        document.body.classList.toggle('menu-active');
    });

    // Close menu when clicked on link inside when the link is for some section on current page
    const headerNavigation = document.querySelector('.header-navigation__content');

    if (headerNavigation) {
        const menuLinks = headerNavigation.querySelectorAll('a');

        menuLinks.forEach((link) => {
            link.addEventListener('click', () => {
                const target = link.getAttribute('href').slice(1);

                if (target && document.getElementById(target)) {
                    document.body.classList.remove('menu-active');
                }
            });
        });
    }

    // Close the menu when the escape key is pressed
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' && document.body.classList.contains('menu-active')) {
            document.body.classList.remove('menu-active');
        }
    });
}
