export default function cssHasPolyfill() {
    // Banner & Intro
    const bannerType2 = document.querySelector('.banner-subpage--type-2');
    const sectionIntro = document.querySelector('.mod_article:first-child .section-intro');

    if (bannerType2 && sectionIntro) {
        sectionIntro.classList.add('has-banner-type-2');
        bannerType2.classList.add('has-section-intro');
    }
}
