export default function () {
    const headerSearchContainer = document.querySelector('[data-header-search]');
    const headerSearchBtnShow = document.querySelector('[data-header-search-show]');
    const headerSearchBtnClose = document.querySelector('[data-header-search-hide]');

    if (!headerSearchContainer || !headerSearchBtnShow || !headerSearchBtnClose) {
        return;
    }

    headerSearchBtnShow.addEventListener('click', (e) => {
        e.preventDefault();
        headerSearchContainer.classList.add('active');
        headerSearchContainer.querySelector('input').focus();
    });

    headerSearchBtnClose.addEventListener('click', (e) => {
        e.preventDefault();
        headerSearchContainer.classList.remove('active');
    });

    // Close the form when the escape key is pressed
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape' && headerSearchContainer.classList.contains('active')) {
            headerSearchContainer.classList.remove('active');
        }
    });
}
