import { throttle } from 'throttle-debounce';

export default function () {
    const header = document.querySelector('.sticky-header');
    const headerPlaceholder = document.createElement('div');
    let stickyHeaderHeight;
    let lastScrollTop = 0;
    let clickedForm = false;

    if (!header) {
        return;
    }

    headerPlaceholder.classList.add('header-placeholder');
    header.parentNode.insertBefore(headerPlaceholder, header);

    function initStickyHeader() {
        header.classList.add('sticky-header--init');
        header.classList.remove('sticky');
        stickyHeaderHeight = header.getBoundingClientRect().height;
        document.documentElement.style.setProperty('--stickyHeaderHeight', `${stickyHeaderHeight}px`);

        setTimeout(() => {
            header.classList.remove('sticky-header--init');
        }, 200);
        updateStickyHeader();
    }

    function updateStickyHeader() {
        const scrollY = window.scrollY || window.pageYOffset;

        headerPlaceholder.style.height = `${stickyHeaderHeight}px`;

        if (clickedForm) {
            lastScrollTop = scrollY <= 0 ? 0 : scrollY;
            header.classList.remove('stuck');

            setTimeout(() => {
                clickedForm = false;
            }, 100);
            return;
        }

        if (scrollY > 20) {
            headerPlaceholder.classList.add('sticky');
            header.classList.add('sticky');
        } else {
            headerPlaceholder.classList.remove('sticky');
            header.classList.remove('sticky');
        }

        if (scrollY === lastScrollTop) {
            return;
        }

        if (scrollY < lastScrollTop) {
            header.classList.add('stuck');
        } else {
            header.classList.remove('stuck');
        }

        lastScrollTop = scrollY <= 0 ? 0 : scrollY;
    }

    window.addEventListener('scroll', throttle(100, () => {
        updateStickyHeader();
    }));

    window.addEventListener('resize', initStickyHeader);

    initStickyHeader();

    document.querySelectorAll('.ce_form form').forEach((form) => {
        form.querySelector('button').addEventListener('click', () => {
            if (!form.checkValidity()) {
                header.classList.remove('stuck');
                clickedForm = true;
            }
        });
    });
}
