import Swiper, {
    Navigation, Autoplay, Pagination,
} from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

Swiper.use([Navigation, Autoplay, Pagination]);

export default function () {
    document.querySelectorAll('[data-carousel-image-text]').forEach((el) => {
        const carouselId = el.dataset.carouselImageText;

        new Swiper(el, {
            loop: el.querySelectorAll('.swiper-slide').length > 1,
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 600,
            navigation: {
                prevEl: `[data-slider-button-prev="${carouselId}"]`,
                nextEl: `[data-slider-button-next="${carouselId}"]`,
            },
        });
    });

    document.querySelectorAll('[data-carousel-job-list]').forEach((el) => {
        const carouselId = el.dataset.carouselJobList;
        const links = el.querySelectorAll('[data-job-name]');

        new Swiper(el, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 600,
            navigation: {
                prevEl: `[data-slider-button-prev="${carouselId}"]`,
                nextEl: `[data-slider-button-next="${carouselId}"]`,
            },
            pagination: {
                el: '.swiper-pagination-buttons',
                clickable: true,
                renderBullet(index, className) {
                    const jobName = links[index].dataset.jobName || index + 1;
                    return `<span class="btn btn-outline-primary ${className}">${jobName}</span>`;
                },
            },
        });
    });

    document.querySelectorAll('[data-carousel-download-boxes]').forEach((el) => {
        const carouselId = el.dataset.carouselDownloadBoxes;

        new Swiper(el, {
            loop: el.querySelectorAll('.swiper-slide').length > 1,
            slidesPerView: 3,
            speed: 600,
            spaceBetween: 20,
            navigation: {
                prevEl: `[data-slider-button-prev="${carouselId}"]`,
                nextEl: `[data-slider-button-next="${carouselId}"]`,
            },
            breakpoints: {
                576: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                768: {
                    slidesPerView: 5,
                },
            },
        });
    });

    document.querySelectorAll('[data-carousel-news]').forEach((el) => {
        const carouselId = el.dataset.carouselNews;

        new Swiper(el, {
            loop: el.querySelectorAll('.swiper-slide').length > 1,
            slidesPerView: 'auto',
            spaceBetween: 30,
            speed: 600,
            watchSlidesProgress: true,
            pagination: {
                el: `[data-carousel-pagination-progressbar="${carouselId}"]`,
                type: 'progressbar',
            },
            navigation: {
                prevEl: `[data-slider-button-prev="${carouselId}"]`,
                nextEl: `[data-slider-button-next="${carouselId}"]`,
            },
        });
    });
}
